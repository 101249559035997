import React from 'react';
import {Carousel} from 'react-bootstrap';
import daniel from '../assets/images/1.png';
import Group6 from '../assets/images/3.png';
import Group9 from '../assets/images/2.png';
import fatima from '../assets/images/4.png';
import Group7 from '../assets/images/5.png';
import maria from '../assets/images/6.png';

const Testimonial = () => {
  const renderCustomPrevIcon = () => (
    <span
      className="carousel-control-prev-icon custom-carousel-arrow"
      aria-hidden="true"
    />
  );
  const renderCustomNextIcon = () => (
    <span
      className="carousel-control-next-icon custom-carousel-arrow"
      aria-hidden="true"
    />
  );

  return (
    <div>
      <div className="testimonial">
        <div className="container" id="testimonial">
          <h3
            className="section-title visitor-slide-title"
            style={{textAlign: 'center'}}
          >
            Voices of Happy <span>Clients</span>
          </h3>
          <Carousel
            prevIcon={renderCustomPrevIcon ()}
            nextIcon={renderCustomNextIcon ()}
            indicators={true}
            interval={5000}
          >

            <Carousel.Item>
              <div className="single-vistor-slide">
                <div className="achieve-img">
                  <img src={fatima} alt="First slide" />
                </div>
                <div>
                  <div className="visiter-slide-ul">
                    <p>
                      The ProJob coaching program gave me the confidence and strategies to excel in interviews. The comprehensive advice on preparation, communication, and storytelling was invaluable. Thanks to the Interview Winning Mastery ebook, I aced my interviews and landed my dream job as Operations Director!"
                    </p>
                    <p
                      style={{
                        fontWeight: '700',
                        textAlign: 'left',
                        fontSize: '25px',
                      }}
                    >
                      {' '}Fatima Namugenyi,
                      <span
                        style={{
                          fontWeight: '400',
                          fontSize: '16px',
                          fontStyle: 'italic',
                        }}
                      >
                        Operations Director
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="single-vistor-slide">
                <div className="achieve-img">
                  <img src={Group6} alt="First slide" />
                </div>
                <div>
                  <div className="visiter-slide-ul">
                    <p>
                      I was feeling stuck in my job search until I discovered the Job Hunters Toolkit eBook. It provided practical tips and techniques to save time and uncover hidden job opportunities. The expert strategies and insights helped me gain a competitive edge in my job search.Thanks to ProJob for this ebook, I am now happily employed in a fulfilling role. Highly recommended!
                    </p>
                    <p
                      style={{
                        fontWeight: '700',
                        textAlign: 'left',
                        fontSize: '25px',
                      }}
                    >
                      David M. Okotch,
                      <span
                        style={{
                          fontWeight: '500',
                          fontSize: '16px',
                          fontStyle: 'italic',
                        }}
                      >
                        {' '}Software Engineer
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="single-vistor-slide">
                <div className="achieve-img">
                  <img src={Group9} alt="First slide" />
                </div>
                <div>
                  <div className="visiter-slide-ul">
                    <p>
                      I can't thank ProJob enough for Mastering Application Tracking Systems eBook that was instrumental in demystifying the ATS process. The valuable tips and techniques taught me how to tailor my resume and stand out in a crowded job market. It's no wonder I received more callbacks and job offers!
                    </p>
                    <p
                      style={{
                        fontWeight: '700',
                        textAlign: 'left',
                        fontSize: '25px',
                      }}
                    >
                      Benson Habimana,
                      <span
                        style={{
                          fontWeight: '500',
                          fontSize: '16px',
                          fontStyle: 'italic',
                        }}
                      >
                        {' '}Senior Sales Executive
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="single-vistor-slide">
                <div className="achieve-img">
                  <img src={Group7} alt="First slide" />
                </div>
                <div>
                  <div className="visiter-slide-ul">
                    <p>
                      ProJob  helped me craft a winning resume that grabbed employers' attention. The expert guidance and examples elevated my resume to a whole new level. Now, I'm proud to say my resume stands out and has opened doors to amazing career opportunities.  I'm now confident in my job applications and have seen a significant increase in interview requests. It's a game-changer! Thank you ProJob!
                    </p>
                    <p
                      style={{
                        fontWeight: '700',
                        textAlign: 'left',
                        fontSize: '25px',
                      }}
                    >
                      Joyce Anderson,
                      <span
                        style={{
                          fontWeight: '400',
                          fontSize: '16px',
                          fontStyle: 'italic',
                        }}
                      >
                        {' '}Project Manager
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="single-vistor-slide">
                <div className="achieve-img">
                  <img src={maria} alt="First slide" />
                </div>
                <div>
                  <div className="visiter-slide-ul">
                    <p>
                      Thanks to ProJob, my LinkedIn profile became a powerful tool for attracting employers. The insights and tips taught me how to build a compelling profile, showcase my skills, and establish meaningful connections. I've received job offers and networking opportunities I never thought possible!"
                    </p>
                    <p
                      style={{
                        fontWeight: '700',
                        textAlign: 'left',
                        fontSize: '25px',
                      }}
                    >
                      Maria Rodriguez,
                      <span
                        style={{
                          fontWeight: '400',
                          fontSize: '16px',
                          fontStyle: 'italic',
                        }}
                      >
                        Marketing Specialist
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item>
              <div className="single-vistor-slide">
                <div className="achieve-img">
                  <img src={daniel} alt="First slide" />
                </div>
                <div>
                  <div className="visiter-slide-ul">
                    <p>
                      The Art of Networking eBook revolutionized my approach to building professional relationships. It provided practical techniques to expand my network, cultivate meaningful connections, and open doors to career opportunities. Networking has become a powerful tool in my career advancement. Thank you!
                    </p>
                    <p
                      style={{
                        fontWeight: '700',
                        textAlign: 'left',
                        fontSize: '25px',
                      }}
                    >
                      {' '}Daniel Thompson, {' '}
                      <span
                        style={{
                          fontWeight: '400',
                          fontSize: '16px',
                          fontStyle: 'italic',
                        }}
                      >
                         Business Consultant
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
