import React, { useState } from 'react'
import logo from '../assets/images/logo.png'
import linkedIn from '../assets/images/linkedIn-green.png'
import Youtube from '../assets/images/Youtube.png'
import { Container } from 'react-bootstrap'

const TermsNavBar = () => {

    // Change navbar bg on scroll
    const [color, setColor] = useState(false)
    const [help, setHelp] = useState(true)

    const changeColor = () => {
        if(window.scrollY >=90){
            setColor(true)
        } else {
            setColor(false)
        }
    }


    const hideHelpline = () => {
        if(window.scrollY >=90){
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    window.addEventListener('scroll', changeColor )
    window.addEventListener('scroll', hideHelpline )
    
  
    const toggleMenu = () => {
      document.getElementById('show-mobile-menu').style.display = 'block'
    }

    const closeMobileMenu = () => {
        document.getElementById('show-mobile-menu').style.display = 'none'
      }


    return ( 
        <div  className={color ? 'nav-bg nav-container' : 'nav-container term-nav'}>
            <div className={help ? 'container helpline' : 'helpline-off'} style={{color:'#FFFFFF'}}><p style={{ width:'100%', marginLeft:'auto', textAlign:'right'}} >help@projob.cc</p></div>
            <Container className='prov-nav'>
                <ul className='nav-list'>
                <a href='/' ><img src={logo} alt='ProJob' className='logo' /></a>
                    {/* <li><a href='#testimonial' >Testimonials</a></li>
                    <li><a href='#offer'>Why Join</a></li>
                    <li> <a href='#products'>Products</a></li>
                    <li>
                    </li> */}
                </ul>
                <ul className='nav-right'>
                    <li>
                        <a href="https://www.linkedin.com/groups/9377399/" target='_blank'  rel="noreferrer">
                            <img src={linkedIn} alt='LinkedIn' />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@pro_Job" target='_blank'  rel="noreferrer">
                            <img src={Youtube} alt='Youtube' />
                        </a>
                    </li>
                    <li>
                        <a href="https://forms.gle/MRaXzzgNakvq8Rkh7">
                            <button className='register-btn'>Register</button>
                        </a>
                    </li> 
                </ul>
                        
                <div className="hamburger-menu">
                    <a href="#products">
                        <div className='register-mobile'>Get Started</div>
                    </a>
                    <div className="hamburger-button" id="hamburger-button" onClick={toggleMenu}>
                        <i class="fa fa-bars" aria-hidden="true"></i>
                    </div>
                </div>
                    <div className='show-mobile-menu' id='show-mobile-menu'>
                        <div className='close' id='close' onClick={closeMobileMenu}><i class="fa fa-times"></i></div>
                        <div className='menu-drop'> 
                            {/* <ul className='top'>
                                <li><a href='#testimonial'>Testimonials</a></li>
                                <li><a href='#offer'>Why Join</a></li>
                                <li> <a href='#products'> Products </a></li>
                            </ul> */}
                            <ul>
                               <li>
                                </li> 
                            </ul>
                            <div className='bottom'>
                                <a href="https://forms.gle/MRaXzzgNakvq8Rkh7" target='_blank'  rel="noreferrer">
                                    <div className='register-drop-btn ' style={{width:'100%', textAlign:'center'}}>Register</div>
                                </a>
                            </div>
                        </div>
                    </div>
            </Container>
        </div >
     )
}
 
export default TermsNavBar;