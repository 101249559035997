import React from 'react';
import about from '../assets/images/offer.png'

const Offer = () => {
    return ( 
        <div className=' offer-container' id='offer'>
            <div className='container'>
                <div className='container about-section'>
                    <img src={about} alt='offer' className='about-img' />
                    <div className='offer-text-section'>
                        <h2 className='section-title about-title'>About <span>Projob</span></h2>
                        <p className='text-line green-par'>
                            Unlock Your Career Success with Projob: The Ultimate Solution for Job Seekers!
                        </p>
                        <p className='text-line offer-text'>Projob is your game-changing ally, providing a personalized coaching program that tackles the unique challenges faced by fresh graduates entering the job market. With our comprehensive resources and expert guidance, you'll gain the essential skills and unwavering confidence to stand out.<br /><br />
                            Our SEO-optimized curriculum homes in-demand abilities such as communication, critical thinking, and writing, skyrocketing your appeal to employers. Craft an irresistible resume with our powerful writing services, ensuring your application shines amidst competition.<br /><br />
                            Learn proven interview tactics and strategies that give you the winning edge in securing coveted positions. Forge strategic connections and unlock exclusive job opportunities, tapping into the vast networks of our accomplished professional, Dr. Benie K. Mooya.<br /><br />
                            Don't let job hunting frustrations hinder your career growth. With Projob, you'll conquer challenges and launch your career with unwavering confidence. Embark on your path to success! Get Started Now!
                        </p>
                    </div>
                </div>
            </div>
        </ div>
     );
}
 
export default Offer;