import React, { useEffect } from 'react';
import MainBanner from './MainBanner';
import Offer from './Offer';
import Video from './Video';
import Testimonial from './Testimonial';
import NavBar from './NavBar';

const Home = ( ) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


  
    return ( 
            <div>
                <NavBar />
                <MainBanner />
                <Offer />
                {/* <ProductsAvailable /> */}
                <Testimonial />
                <Video />
            </div>
            
     );
}
 
export default Home;