import React, { useEffect } from 'react';
import Testimonial from './Testimonial';

import image6 from '../assets/images/Component6.png'
import NavBar from './NavBar';

const SingleProduct = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return ( 
        <div>
            <NavBar />
            <div className='product-single-top '>
                <div className='container'>

                {/* {ProductsData.map((data, index) => {
                            if(data.id == id) {
                                return (
                                    <div className='details-cont'>
                                        <div className='details-left'>
                                            <img src={data.image} alt='details product' className='details-img' />
                                            <p className='total-price'>total price: &emsp;$30</p>
                                        </div>
                                        <div className='details-right'>
                                            <h3 className='single-title'>{data.text}</h3>
                                            <p className='short-desc'>Suspendisse elementum tellus et est aliquet sit amet</p>
                                            <p className='single-price'><span>$30</span> Price</p>
                                            <p className='package-desc'>Package Description</p>
                                            <p>
                                                {data.text}
                                            </p>
                                            <p className='ready-to-win-it'>Ready to win it <button>Buy Now</button> </p>
                                        </div>
                                    </div>
                                )
                            }
                        })} */}

                    <div className='details-cont'>
                        <div className='details-left'>
                            <img src={image6} alt='details product' className='details-img' />
                            <p className='total-price'>total price: &emsp;$30</p>
                        </div>
                        <div className='details-right'>
                            <h3 className='single-title'>Job Search Techniques</h3>
                            <p className='short-desc'>Suspendisse elementum tellus et est aliquet sit amet</p>
                            <p className='single-price'><span>$30</span> Price</p>
                            <p className='package-desc'>Package Description</p>
                            <p>
                                Projob is your game-changing ally, providing a personalized coaching program that tackles the unique challenges faced by fresh graduates entering the job market. With our comprehensive resources and expert guidance, you'll gain the essential skills and unwavering confidence to stand out.<br />
                                Our SEO-optimized curriculum homes in-demand abilities such as communication, critical thinking, and writing, skyrocketing your appeal to employers. Craft an irresistible resume with our powerful writing services, ensuring your application shines amidst competition.<br />
                                Learn proven interview tactics and strategies that give you the winning edge in securing coveted positions. Forge strategic connections and unlock exclusive job opportunities, tapping into the vast networks of our accomplished professional, Dr. Benie K. Mooya.<br />
                                Don't let job hunting frustrations hinder your career growth. With Projob, you'll conquer challenges and launch your career with unwavering confidence. Embark on your path to success! Get Started Now!
                            </p>
                            <ul>
                                <li>Gain an Unfair Advantage</li>
                                <li>Laser-Focused Guidance</li>
                                <li>Tailored Resumes that Get Noticed</li>
                                <li>Master Winning Interviews</li>
                                <li>Network Like a Pro</li>
                                <li>Boost Your Confidence</li>
                            </ul>
                            <p className='ready-to-win-it'>Ready to win it <button>Buy Now</button> </p>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonial />
        </div>
     )
}
 
export default SingleProduct;