import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import linkedin from '../assets/images/Linkedin.png'
import twitter from '../assets/images/Twitter.png'
import facebook from '../assets/images/Facebook.png'
import youtubef from '../assets/images/YoutubeF.png'
import logo from '../assets/images/logo.png'
import { useNavigate } from 'react-router-dom'

const Footer = () => {

    const navigate = useNavigate()

    const handleTerms = () => {
        navigate('/terms')
    }
    
    const handlePrivacy = () => {
        navigate('/privacy')
    }

        
    const handlehome = () => {
        navigate('/')
    }

    return ( 
        <div className='footer'>
            <Container>
                <Row>
                    <Col md={5}>
                        <div className='footer-col'>

                            <img src={logo} alt='ProJob' className='logo-footer' onClick={handlehome} style={{cursor:'pointer'}} />
                            <p className='footer-p'>Your trusted ally for fresh graduates. Our personalized coaching, expert guidance, and comprehensive resources equip you to conquer job market challenges. Gain confidence, essential skills, and exclusive opportunities to launch your career successfully.</p>
                            <div className='social-section'>
                                <a href='https://www.youtube.com/@pro_Job' target='_blank'  rel="noreferrer"><img src = {youtubef} alt='youtube' /></a>
                                <a href='https://www.linkedin.com/company/projobu' target='_blank'  rel="noreferrer"><img src = {linkedin} alt='linkedin' /></a>
                                <a href='https://twitter.com/33mooya' target='_blank'  rel="noreferrer"><img src = {twitter} alt='twitter' /></a>
                                <a href='https://www.facebook.com/33mooya' target='_blank'  rel="noreferrer"><img src = {facebook} alt='instagram' /></a>
                            </div>
                        </div>
                    </Col>

                    <Col className='footer-col'>
                        <p className='useful-title'>USEFUL LINKS</p>
                        <ul>
                            <li><a href='#offer'>About</a></li>
                            <li onClick={handleTerms} style={{cursor:'pointer'}}>Terms</li>
                            <li onClick={handlePrivacy} style={{cursor:'pointer'}}>Privacy</li>
                            <li><a href = "mailto: help@projob.cc">Contact</a></li>
                        </ul>
                    </Col>

                    <Col className='footer-col'>
                        <h1 className='footer-title'>Ready to launch<br/> your <span>career?</span> </h1>
                        <a href="https://forms.gle/MRaXzzgNakvq8Rkh7" target='_blank'  rel="noreferrer">
                            <button className='footer-btn'>Register</button>
                        </a>
                    </Col>
                </Row>
                {/* <Row> */}
                    <p className='copyright'><small>Copyright &copy; 2023 Projob. All right reserved</small></p>
                {/* </Row> */}
                
            </Container>
        </div>
     )
}
 
export default Footer;