import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './components/home';
import SingleProduct from './components/SingleProduct';
import Footer from './components/Footer';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <div className="App">
      <Router>
        
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/SingleProduct/:id' element={<SingleProduct />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
