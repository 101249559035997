import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import manRunning from '../assets/images/man-running.png';
import directinbox from '../assets/images/directinbox.png';
import directboxnotif from '../assets/images/directboxnotif.png';
import directsend from '../assets/images/directsend.png';
import documentdownload from '../assets/images/documentdownload.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import $ from 'jquery';
import axios from 'axios';

const MainBanner = () => {
  const [formData, setFormData] = useState ({
    name: '',
    email: '',
    phone: '',
    brand: '',
  });

  const [formError, setFormError] = useState ({
    name: '',
    email: '',
    phone: '',
    brand: '',
  });
  const [getCopy, setGetCopy] = useState ('Get Your Free Copy');

  const handleFormDate = event => {
    const data = {...formData};
    const name = event.target.name;
    data[name] = event.target.value;
    const error = {...formError};
    error[name] = '';
    setFormError (error);
    setFormData (data);
  };

  const handleOrder = () => {
    // var myModal = document.getElementById('getNowBtn');

    const modalElement = document.querySelector ('#getNowBtn');
    const modalElement2 = document.querySelector ('#OrderSteps');
    const status = modalElement.classList.contains ('show');
    const status2 = modalElement2.classList.contains ('show');
    const mainElement = document.getElementById ('mainBanner');

    if (status || status2) {
      document.body.style.overflow = 'hidden';
      mainElement.style.background = 'white';
    } else {
      document.body.style.overflow = 'auto';
      mainElement.style.background = '';
    }
  };

  useEffect (() => {
    setInterval (() => {
      handleOrder ();
    }, 1000);
  });

  const handleThanks = e => {
    e.preventDefault ();

    const validateStatus = validateForm ();

    if (validateStatus) {
      setGetCopy ('Sending........');

      axios
        .post ('https://projob.cc/api/public/api/v1/get-copy', formData)
        .then (response => {
          const res = response.data;
          if (res.result) {
            const dataEmpty = {
              name: '',
              email: '',
              phone: '',
              //brand: '',
            };
            setFormData ({...formData, ...dataEmpty});
            setGetCopy ('Get Your Free Copy');

            const btn = document.getElementById ('firstBtnClose');
            btn.click ();

            const secondBtn = document.getElementById ('seond_stage');
            secondBtn.click ();
          }
        })
        .catch (error => {
          const status = error.response.status;

          console.log(error.response)
          if (status == 422&&error.response.data.result=='email') {
            const obj = {email: 'This  email address has already been used. If you think there is an error or need further assistance, please contact help@projob.cc'};

            setFormError ({...formError, ...obj});

            setGetCopy ('Get Your Free Copy');
          }

          if (status == 422&&error.response.data.result=='phone') {
            const obj = {phone: 'This phone number has already been used. If you believe there is a mistake or require additional help, please get in touch at help@projob.cc'};

            setFormError ({...formError, ...obj});

            setGetCopy ('Get Your Free Copy');
          }
          //console.log(error.response)
        });
    }
  };

  const setPhone = phone => {
    // console.log(phone, 'test phone')
    const data = {...formData};
    data['phone'] = phone;

    setFormData (data);

    const error = {...formError};
    error['phone'] = '';
    setFormError (error);
  };

  const validateForm = () => {
    var status = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const obj = {};
    if (!formData.name) {
      obj['name'] = 'Name field is required';

      status = false;
    }
    if (!formData.email && !emailRegex.test (formData.email)) {
      obj['email'] = 'Email field is required';
      status = false;
    }
    if (!emailRegex.test (formData.email)) {
      obj['email'] = 'Email field is invalid';
      status = false;
    }
    if (!formData.phone) {
      obj['phone'] = 'Phone field is required';

      status = false;
    }
    if (!formData.brand) {
      obj['brand'] = 'Terms and Policy check box field is not checked';

      status = false;
    }

    const oldObjects = {...formError, ...obj};
    setFormError (oldObjects);

    return status;
  };

  const close = () => {
    const btn = document.getElementById ('complete-step');
    btn.click ();
  };

  return (
    <div className="main-banner" id="mainBanner">

      <Container className="main-container">

        <div className="main-text">
          <h2 className="main-title">
            Launch Your Career with
            <br />
            {' '}
            <span>Confidence</span>
            {' '}
            and Get
            {' '}
            <span>Hired</span>
            {' '}
            Faster!
          </h2>
          <p className="text-line">
            Get Personalized Coaching from Successful Professionals to Help You Acquire the Skills and Confidence You Need to Secure Your First Job
          </p>
          <div className="free-job-hunt">
            <p className="tool-kit">Free Powerful Resume Guide</p>
            <a href="#products">
              <button
                className="get-it-now"
                data-toggle="modal"
                data-target="#getNowBtn"
              >
                Get it Now
              </button>
            </a>
          </div>

          <div
            style={{zIndex: -200}}
            data-toggle="modal"
            id="seond_stage"
            data-target="#OrderSteps"
          />
        </div>
        <img src={manRunning} alt="man running" className="man-running" />
      </Container>

      <div
        className="modal fade"
        id="getNowBtn"
        tabindex="-1"
        role="dialog"
        aria-labelledby="getNowBtnTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-width"
          role="document"
        >

          <div className="modal-content">

            <div className="modal-body">
              <div id="order-now">
                <div className="job-hunt">
                  <div>
                    <p className="supercharge">
                      Supercharge Your Resume with the Ultimate Guide for Success!
                    </p>
                    <ul>
                      <li>Impress Employers</li>
                      <li>Pass ATS screening</li>
                      <li>Secure More Interviews </li>
                      <li>Stand Out from Competetitors</li>
                      <li>Unlock  Career Opportunities</li>
                      <li>Nail the format and Design</li>
                    </ul>
                  </div>
                  <p style={{marginTop: 202}}>
                    Tired of your resume falling flat and missing out on job Opportunities? Ready to make control of your career and captivate employers with a powerful resume?
                    {' '}
                    looking to increase your chances of landing interviews
                  </p>
                </div>
                <div className="hunt-form">
                  <div style={{display: 'flex'}}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      id="firstBtnClose"
                      style={{
                        marginLeft: 'auto',
                        marginTop: -26,
                        marginRight: -26,
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <p className="pop-up-title">ENTER YOUR DETAILS</p>
                  <p
                    className="pop-up-title"
                    style={{textAlign: 'center', lineHeight: 1}}
                  >
                    To Receive Free Powerful Resume Guide
                  </p>
                  <form className="hunt-form-form">
                    <input
                      type="text"
                      className="order-inputs"
                      name="name"
                      placeholder="Name"
                      value={formData['name']}
                      onChange={e => handleFormDate (e)}
                    />
                    {formError.name &&
                      <div className="invalid-feedback-cus">
                        {formError.name}
                      </div>}

                    <input
                      type="email"
                      className="order-inputs"
                      name="email"
                      placeholder="Email"
                      onChange={e => handleFormDate (e)}
                      value={formData['email']}
                    />
                    {formError.email &&
                      <div className="invalid-feedback-cus">
                        {formError.email}
                      </div>}

                    <PhoneInput
                      placeholder="Enter phone number"
                      country={'ug'}
                      value={formData['phone']}
                      onChange={setPhone}
                      containerStyle={{
                        border: '1px solid black',
                        borderRadius: '5px',
                        width: '100%',
                        height: 50,
                        border: '1px solid #ced4da',
                      }}
                      inputStyle={{
                        height: 48,
                        width: '100%',
                      }}
                    />
                    {formError.phone &&
                      <div className="invalid-feedback-cus">
                        {formError.phone}
                      </div>}
                    <div>
                      <div className="radio-div">
                        <input
                          type="radio"
                          id="order"
                          name="brand"
                          value="Agreed"
                          onChange={e => handleFormDate (e)}
                        />
                        <div for="order">
                          I agree with
                          {' '}
                          <a
                            href="/terms"
                            style={{color: '#2AD883'}}
                            target="_blank"
                          >
                            terms
                          </a>
                          {' '}
                          and
                          {' '}
                          <a
                            href="/privacy"
                            style={{color: '#2AD883'}}
                            target="_blank"
                          >
                            privacy
                          </a>
                        </div>
                      </div>
                    </div>
                    {formError.brand &&
                      <div className="invalid-feedback-cus">
                        {formError.brand}
                      </div>}
                    <button
                      className="order-now-btn"
                      onClick={handleThanks}
                      disabled={getCopy != 'Get Your Free Copy'}
                    >
                      {getCopy}
                    </button>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="OrderSteps"
        tabindex="-1"
        role="dialog"
        aria-labelledby="OrderStepsTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-width"
          style={{maxWidth: '80%'}}
          role="document"
        >
          <div className="modal-content">

            <div className="modal-body">

              <div id="thanks">
                <div className="thank-order">
                  <p className="thank-title">Thank You for Ordering with Us</p>
                </div>
                <div className="hunt-form">
                  <div style={{display: 'flex'}}>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      id="complete-step"
                      aria-label="Close"
                      style={{
                        marginLeft: 'auto',
                        marginTop: -26,
                        marginRight: -26,
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <p className="thanks-title">
                    Follow the steps to get your Powerful Resume Guide in your email
                  </p>
                  <div className="hunt-form-form">
                    <div className="hunting-kit">
                      <div> <img src={directinbox} alt="directinbox" /> </div>
                      <div className="check-list">
                        <span>1. Check your email inbox</span> <br />
                        Go to the inbox of the e-mail address you used to sign up.
                      </div>
                    </div>
                    <div className="hunting-kit">
                      <div>
                        {' '}<img src={directboxnotif} alt="directinbox" />{' '}
                      </div>
                      <div className="check-list">
                        <span>2. Open our email</span> <br />
                        Find the email we just sent you. It has the subject line “Powerful Resume Guide”.
                      </div>
                    </div>
                    <div className="hunting-kit">
                      <div> <img src={directsend} alt="directinbox" /> </div>
                      <div className="check-list">
                        <span>3. Check Junk or Spam: </span> <br />
                        If you dont find the email in your inbox please check the junk or spam folders.
                      </div>
                    </div>
                    <div className="hunting-kit">
                      <div>
                        {' '}
                        <img src={documentdownload} alt="directinbox" />
                        {' '}
                      </div>
                      <div className="check-list">
                        <span>4. Click the download link </span> <br />
                        The download process will start automatically.
                      </div>
                    </div>
                    <button className="order-now-btn" onClick={close}>
                      Alright, Got it{' '}
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

export default MainBanner;
