import React from 'react';
import { Container } from 'react-bootstrap';

const Video = () => {
    return ( 
        <div className='video-section' id='why-join'>
            <Container className='video-width'>
                <h3 className='section-title'>Why is Your Success Guaranteed?</h3>
                <p className='text-line video-text'>Our coaching program offers a guarantee of success because it is designed and led by accomplished professional Dr. Benie K. Mooya. Watch this short video clip to learn more</p>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/-LTEbA7T3ZI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                
            </Container>
        </div>
     );
}
 
export default Video;